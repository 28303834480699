<template>
  <div v-if="show">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <h1 class="mb-4">Let's Excelerate you journey</h1>
                <h5 class="mb-4">
                  Spend 3 minutes to become Rising Star and unlock Thesis
                  Collaboration Matching
                </h5>
                <h5 class="mb-4">Lets get started 🚀</h5>
                <div class="text-center">
                  <router-link
                    class="btn btn-white w-200"
                    to="/user/student-rising-star"
                    ><img class="w-30" src="~@/assets/risingstar_icon_pink.png" alt="" srcset=""> RISING STAR</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
  props: ['show']};
</script>
<style lang="css" scoped>
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0% 0% no-repeat padding-box;
  color: white;
  max-width: 600px;
  padding: 5%;
  border-radius: 10px;
}
.w-30 {
  width: 30px;
}
</style>
