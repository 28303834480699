import { render, staticRenderFns } from "./UpdateCollaboration.vue?vue&type=template&id=7be00f8d&scoped=true"
import script from "./UpdateCollaboration.vue?vue&type=script&lang=js"
export * from "./UpdateCollaboration.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./UpdateCollaboration.vue?vue&type=style&index=1&id=7be00f8d&prod&scoped=true&lang=css"
import style2 from "./UpdateCollaboration.vue?vue&type=style&index=2&id=7be00f8d&prod&lang=css"
import style3 from "./UpdateCollaboration.vue?vue&type=style&index=3&id=7be00f8d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7be00f8d",
  null
  
)

export default component.exports