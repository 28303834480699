var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('editor',{attrs:{"api-key":"6s8agpscdhpz5jrgn43hwbrncq9icboi51103m0r2j5qkv1y","output-format":"html","initial-value":_vm.value,"disabled":_vm.disabled,"init":{
      height: 450,
      menubar: false,      
      branding: false,
      plugins: ['lists', 'paste'],
      paste_as_text: true,
      statusbar: false,
      toolbar: 'formatselect bold italic |  numlist bullist',
    }},on:{"input":_vm.updateValue},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }